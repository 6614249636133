<script setup>

</script>

<template>
  <p class="paragraphe" v-html="$t('module3.part3.lesson1.step7.content')"></p>
</template>

<style scoped lang="scss">
@import '../../../../../styles/module3Lesson';
</style>
